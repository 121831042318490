
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
.wrapper {
  padding: $space-xs 0 $space-sm;

  > * {
    display: inline-block;
    margin-right: 0.3rem;
    white-space: nowrap;
  }

  &.large {
    max-width: 50%;
    padding-left: $space-xs;
  }
}

.startingPrice {
  font-size: $font-small;

  .large & {
    display: block;
    font-size: $font-normal;
  }
}

.price {
  font-size: $font-normal;
  font-weight: bold;

  .large & {
    font-size: $font-large;
  }
}

.newPrice {
  color: $color-ethiopia-primary;
}

.oldPrice {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    border-top: $border-width solid $color-text-secondary;
    opacity: 0.6;
    transform: rotate(-7deg);
    width: 100%;
    top: 50%;
    left: 0;
  }
}

.metadata {
  font-size: $font-x-small;
  vertical-align: baseline;
  color: $color-text-secondary;
  white-space: nowrap;
}
